<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Transaction Adjustment",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Transaction Adjustment",
      items: [
        {
          text: "Transaction",
          href: "/",
        },
        {
          text: "Adjustment",
          active: true,
        },
      ],
      form: {
        status: "",
        reason: "",
      },
      adjustment: {},
      reason: "",
      amount: "",
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      status: { required },
      reason: { required },
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    // post adjustment
    async approveAdjustment() {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to approve this adjustment?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        this.formSubmit();
        try {
          const response = await axios.patch(
            `${process.env.VUE_APP_BASE_URL}/adjust-transaction-request/status`,
            {
              adjustTransactionRequestId: this.$route.params.id,
              status: this.form.status,
              reason: this.form.reason,
            }
          );
          this.$toast.success(response.data.message);
          this.$router.push("/admin/adjustments");
        } catch (error) {
          this.$toast.error(error.response.data.message);
        }
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "The adjustment was not approved.",
          icon: "error",
          confirmButtonColor: "#3085d6",
        });
      }
    },
    async getAdjustment() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/adjust-transaction-request/${this.$route.params.id}`
        );
        if (response.status === 200 || response.status === 201) {
          this.adjustment = response.data.transaction;
          this.reason = response.data.reason;
          this.amount = response.data.amount;
        } else {
          this.throwError();
        }
      } catch (error) {
        this.handleError(error.response.data.message);
      }
    },
  },
  mounted() {
    this.getAdjustment();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Approve Adjustment</h4>
            <p class="card-title-desc">
              Admin aprroval for agent transaction adjustment
            </p>
            <div class="font-size-18">
              <p class="mb-0 text-truncate mb-2">
                Agent Name : {{ adjustment.agentName }}
              </p>
              <p class="mb-0 text-truncate mb-2">
                Customer Name : {{ adjustment.customerName }}
              </p>
              <p class="mb-0 text-truncate mb-2">
                Adjustment Reason : {{ reason }}
              </p>
              <p class=" mb-0 text-truncate mb-2">
                Original Amount : GHS 
                {{ adjustment.initialAmount }}
              </p>
              <p class="mb-0 text-truncate mb-2">
                Adjusted To : GHS {{ amount }}
              </p>
            </div>

            <form
              class="needs-validation"
              @submit.prevent="approveAdjustment()"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group row">
                    <label class="col-md-2 col-form-label"
                      >Approval Status (Choose)</label
                    >
                    <div class="col-md-12">
                      <select
                        class="form-control"
                        v-model="form.status"
                        :class="{
                          'is-invalid': submitted && $v.form.status.$error,
                        }"
                      >
                        <option value="Accepted">Accepted</option>
                        <option value="Rejected">Rejected</option>
                      </select>
                      <div
                        v-if="submitted && $v.form.status.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.status.required"
                          >This value is required.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Reason</label>
                    <textarea
                      id="validationCustom02"
                      v-model="form.reason"
                      type="text"
                      class="form-control"
                      placeholder="Reason"
                      :class="{
                        'is-invalid': submitted && $v.form.reason.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.reason.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.reason.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Submit form</button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
  </Layout>
</template>
